import React, { useState, useEffect, useContext } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import BlurLoader from "../../components/generic/loaders/blur-loader"
import AccountWrapper from "../../components/account/account-wrapper"
import LinkWrapper from "../../lib/link-wrapper"

import { getUser } from "../../context/auth/auth-functions"
import AuthContext from "../../context/auth/auth-context"

const MyAccount = ({ location, data }) => {
  const [loading, setLoading] = useState(true)
  const [userName, setUserName] = useState("")
  const { performLogout } = useContext(AuthContext)

  // Site info
  const siteTitle = data.seaSiteInfo.site_title || ""

  // Fetch address from WC
  useEffect(() => {
    setLoading(false)
    const { user_display_name } = getUser()
    setUserName(user_display_name)
    return
  }, [])

  return (
    <AccountWrapper location={location} accountStep="my-account">
      <BlurLoader loading={loading} message="Updating">
        <WelcomeToMyAccountStyling>
          <h3>
            Welcome <UserNameStyling>{userName} </UserNameStyling>to your{" "}
            {siteTitle} account page
          </h3>
          <p>
            In brief, from here you can view your order history and edit your
            shipping details.
          </p>
          <p>
            If you have any problems with your order, please
            <StyledLinkWrapper to="/contact-us/">
              {" contact us "}
            </StyledLinkWrapper>
            immediately.
          </p>
          <LogoutStyling>
            {`Not ${userName}?`}
            <button onClick={() => performLogout("/")}>Logout</button>
          </LogoutStyling>
        </WelcomeToMyAccountStyling>
      </BlurLoader>
    </AccountWrapper>
  )
}

export default MyAccount

// ======================
//         STYLES
// ======================
const StyledLinkWrapper = styled(LinkWrapper)`
  font-weight: 600;
`
const WelcomeToMyAccountStyling = styled.div`
  h3 {
    text-transform: none;
  }
  p:last-of-type {
    margin: 0;
  }
`
const UserNameStyling = styled.span`
  font-weight: 600;
`
const LogoutStyling = styled.div`
  align-items: center;
  padding-top: 3rem;
  button {
    margin-top: 1rem;
  }
`

// ===============
//      QUERY
// ===============
export const query = graphql`
  {
    seaSiteInfo {
      site_title
    }
  }
`
